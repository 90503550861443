import React from "react";
import { Helmet } from "react-helmet";

import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Layout from "../components/Layout";

const SITE = "Valle Guadalupe Baja";
const DOMAIN = "valleguadalupebaja.com";

const PrivacyPolicyPage = () => {
    const useStyles = makeStyles((theme) => ({
        textblock: {
            marginTop: 30,
        },
    }));
    const styles = useStyles();
    return (
        <Layout pageName="privacypolicy">
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <Container align="justify" maxWidth="md">
                <Box sx={{ mt: 4 }}>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="subtitle1"
                    >
                        Last Modified September 24th 2020
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        1) Introduction
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        This Privacy Policy is {SITE}'s official policy relating
                        to the collection and use of information from you. This
                        policy explains how and why {SITE} collects your
                        personal information, how it is used, and what controls
                        you have over our use of it. {SITE} is committed to
                        complying with applicable laws governing the collection
                        and use of personal information and to protecting and
                        safeguarding your privacy when you deal with us. 2)
                        Acceptance
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        By you sending an email, using any website of {SITE}, or
                        by installing, accessing and/or using the products and
                        services offered by {SITE}, you consent to the
                        collection of information in accordance with this
                        Privacy Policy and other agreements between us. You
                        acknowledge and agree that your use of {SITE} websites
                        or products constitutes your acceptance of this Privacy
                        Policy and the General Terms of Use.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        3) Types of Information Collected
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} may collect a range of information in the
                        ordinary course of its business activities, including
                        personal and non-personal information, including your
                        name, phone number, email, IP address, all relating to
                        your use of {SITE} products and/or services including
                        websites.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        4) Primary Purpose of collection
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} collects information in the course of its
                        business activities in order to: a) carry on its
                        business and to provide you with products and/or
                        services; b) provide marketing and obtain feedback about
                        our products and services; and c) deliver access to and
                        contents of websites with improved efficiency and
                        functionality. Without this information, {SITE} may be
                        unable to provide its products and/or services to you.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        5) How {SITE} Collects Information
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} collects information in two main ways: a)
                        directly where you: i) buy or obtain a subscription to
                        our products and/or services; ii) contact us to obtain
                        support or make an inquiry in respect of our products or
                        services; iii) submit your email address and other
                        information for inclusion in our database; iv) browse
                        any of {SITE} active websites or domains. b) indirectly,
                        for instance, via the placement of browser cookies or
                        other analytical tools on computers and devices used by
                        you. At or before the time we collect information, we
                        will take reasonable steps to inform you about such
                        collection, and draw your attention to this Privacy
                        Policy, including {SITE} Cookie Policy.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        6) How Your Information Will Be Used
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        You acknowledge and agree that {SITE} may use or
                        disclose information for the primary purpose for which
                        it is collected, including but not limited to, i)
                        provision of services and/or products, administration
                        related thereto, fulfilment of orders placed by you,
                        marketing and notification about changes to our services
                        and/or products, and general record-keeping related to
                        your use of our products and/or services, register and
                        handle support, communicate with you or to comply with
                        our legal obligations; and ii) may be disclosed on a
                        confidential basis to our third party contractors,
                        agents or to our suppliers related to the primary
                        purposes; We may also use such information for a purpose
                        related to the primary purpose of collection and where a
                        reasonable ordinary person would expect that we would
                        use the information in such a way. Where you provide
                        personal information to us about another person, you
                        warrant that you are authorised to provide that
                        information to us, and that you will inform that person
                        who we are, how we use and disclose their information,
                        and that they can gain access to that information.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        7) Disclosure of Information
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        Your information will only be disclosed to parties
                        outside of {SITE} as set out in this Privacy Policy or
                        as otherwise notified to you at the time of collection
                        of the information. {SITE} may be required to disclose
                        your information where: a) we reasonably suspect that
                        there is a threat to your health or safety or the
                        public’s health or safety; b) we reasonably suspect that
                        illegal or unlawful activity has been, is being or may
                        be engaged in, and the use or disclosure is a necessary
                        part of our investigation or in reporting the matter to
                        the relevant authorities; and/or c) where such use or
                        disclosure is required under or authorised by law (for
                        example, to comply with a subpoena, a warrant or other
                        order of a court or legal process).
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        8) Third Party Sources
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} website or other materials may contain links to
                        websites owned and operated by third parties, who may
                        collect personal information about you. Once you
                        navigate away from {SITE} webpage(s), this Privacy
                        Policy no longer applies, and {SITE} disclaims any
                        responsibility for the privacy practices of such third
                        parties, or the content they provide. You should always
                        review the privacy policies of all third parties with
                        whom you interact.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        9) Cross Border Transfer, Security and Storage
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        By accessing a {SITE} website, or by installing,
                        accessing or using {SITE} products and/or services, you
                        agree and consent to the cross-border transfer of
                        information and/or data. {SITE} uses third parties,
                        including Amazon Web Services and Google to host its
                        emails, databases and systems on and has entered into
                        suitable agreements to protect your data. Those
                        providers are generally located in Australia, the
                        European Union and/or the European Economic Area and/or
                        the United States of America. {SITE} uses commercially
                        reasonable physical, managerial, and technical
                        safeguards to preserve the integrity and security of
                        personally identifiable information, including having
                        secure data networks protected by industry standard
                        firewall and password protection systems. Data
                        transmission over the Internet may not be secure and you
                        transmit all information at your own risk. Upon receipt
                        of your transmission, {SITE} make every effort to ensure
                        its security on our systems. You are solely responsible
                        for keeping your passwords and/or account information
                        secret, ensure your computer systems are up to date and
                        implement antivirus and other protective measures.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        10) Contacts, Access to and Correction of Personal
                        Information
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} is committed to maintaining accurate, relevant
                        and appropriate information about our customers, client
                        and other stakeholders. You may contact us and/or
                        request access to your information via email: privacy@
                        {DOMAIN} {SITE} will review your request in accordance
                        with applicable privacy laws and will provide you with
                        access to a copy of the information it holds about you,
                        and will permit you to update or change that
                        information, unless: you cannot satisfy {SITE} that you
                        are entitled to access the information; or
                        {SITE} is entitled to refuse or withhold such access. If
                        you are refused access, we will provide reasons for such
                        refusal upon your request. For security reasons, {SITE}{" "}
                        will not disclose sensitive security details including
                        such things as usernames, passwords or credit card
                        information.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        11) Complaints
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        If you have a complaint about our Privacy Policy or the
                        collection, use or disposal of your personal
                        information, your complaint should be directed by email
                        (see contact details above). We will review your
                        complaint and attempt to resolve it. If you are not
                        satisfied with the outcome of this review, then you may
                        request that an independent person investigate your
                        complaint.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="h6"
                    >
                        12) Changes to Privacy Policy
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        This Privacy Policy may be revised periodically without
                        further notice to you and this will be reflected by the
                        “last modified” notice above. Please revisit this page
                        to stay aware of any changes. Your continued use of{" "}
                        {SITE} products and services, including this website
                        constitutes your agreement to this Privacy Policy and
                        any future revisions.
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
};

export default PrivacyPolicyPage;
